<template>
  <nuxt-child />
</template>

<script>
export default {
  name: 'Index',
  auth: true,
  scrollToTop: true,
  middleware: ({ route, redirect }) => {
    if (route.path === '/') {
      return redirect('/search?view=overview')
    }
  },

  async fetch({ store, route }) {
    if (route.path.includes('/search')) {
      if (!store.state.search.isInitialized) {
        const notUndefined = obj => !(obj === void 0 && obj === 'undefined')
        const isPositiveWholeNumber = num => !!(Number(num) > 0)

        const { view, p: page, r: limit } = route.query
        const { pageLimits } = store.state.search
        const { activeAggregateViews } = store.state.views

        if (notUndefined(view) && activeAggregateViews.includes(view)) {
          store.commit('views/setActiveAggregateView', view)
        } else {
          store.commit('views/setActiveAggregateView', activeAggregateViews[0])
        }

        if (notUndefined(page) && isPositiveWholeNumber(page)) {
          store.dispatch('search/commitActiveViewCurrentPage', page)
        } else {
          store.dispatch('search/commitActiveViewCurrentPage', 1)
        }

        if (notUndefined(limit) && isPositiveWholeNumber(limit)) {
          store.dispatch('search/commitActiveViewPageLimit', limit)
        } else {
          store.dispatch('search/commitActiveViewPageLimit', pageLimits[0])
        }
      }

      const promise = store.dispatch('search/initialize', route)

      if (!store.state.isMounted) {
        await promise
      }
    }
  }
}
</script>
